import React, { Component, Fragment } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  Button,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Form,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import api from "../utils/api";
import storage from "../utils/storageFactory.js";
import helpers from "../utils/helpers";

export class Login extends Component {
  displayName = Login.name;
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      message: null,
      messageFlavor: null,
    };
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUsername(event) {
    this.setState({ username: event.target.value });
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    api
      .post("public/authenticate", {
        Username: this.state.username,
        Password: this.state.password,
      })
      .then((r) => {
        if (r.data && r.data.success === false) {
          this.setState({ messageFlavor: "danger", message: r.data.message });
        } else if (r.data && r.data.success) {
          this.setState({
            messageFlavor: "success",
            message: "Login Successful!",
          });
          storage.setItem('SSO', '0');
          this.props.setCurrentUser(
            r.data.user,
            r.data.user.token,
            r.data.nextAppt
          );
        } else {
          throw new Error("Unable to authenticate - missing response");
        }
      })
      .catch(helpers.catchHandler);
  }

  render() {
    return (
      <Fragment>
        <Row>
          <Col xs={{ size: 6, offset: 3 }} md={{ size: 3, offset: 4 }}>
            <Card className="m-2" id="logoCard">
              <CardImg
                src="/Content/Images/peakhealth_logo_2023_stacked.jpg"
                alt="Logo"
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 5, offset: 1 }} sm="12" className="mt-2">
            <Card>
              <CardHeader>Log On</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    {this.state.message !== null ? (
                      <Alert className="ml-3" color={this.state.messageFlavor}>
                        {this.state.message}
                      </Alert>
                    ) : null}
                    <Form onSubmit={this.handleSubmit}>
                      <Row className="mb-2">
                        <Col sm="12">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText style={{ width: "95px" }}>
                                Username
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              name="username"
                              id="username"
                              onChange={this.handleUsername}
                              value={this.state.username}
                              placeholder="Username"
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col sm="12">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText style={{ width: "95px" }}>
                                Password
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="password"
                              name="password"
                              id="password"
                              onChange={this.handlePassword}
                              value={this.state.password}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="expand-md">
                        <Col className="text-right">
                          <div className="text-left">
                            <Link
                              to={{
                                pathname: "/forgot-password",
                                state: { username: this.state.username },
                              }}
                            >
                              {" "}
                              Forget your password?{" "}
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      <Row className="expand-md">
                        <Col className="text-right">
                          <div className="btn-group">
                            <button
                              className="btn btn-primary"
                              type="submit"
                              value="Log On"
                            >
                              Log On
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="5" sm="12" className="mt-2">
            <Card>
              <CardHeader>For Truist and TIH Participants Only</CardHeader>
              <CardBody>
                <form method="POST" action="SSO/Init/420">
                  <div style={{ fontStyle: "italic" }}>
                    This single sign-on feature allows Truist and TIH users to log in
                    once and access your Peak Health portal without re-entering
                    authentication factors/credentials.
                  </div>
                  <Row className="mt-3">
                    <Col>
                      <Button type="submit" className="btn btn-primary btn-block">
                        Truist
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn btn-info btn-block">
                        TIH
                      </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
